.Polihome1{
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

}
.Polihome{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
   flex-wrap: wrap;
   padding: 0 1px;
}
.Poliindex{
     display: flex;
     align-items: center;
     justify-content: space-between;
     width: 90%;
   transition: 0.3s;
}
.Poliindex >p{
    border-top: 3px solid green;
    padding: 10px 20px;
    background-color: white;
    width: 150px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.Poliindex >a{
   
    padding: 7px 10px;
    transition: 0.3s;
}
.Poliindex >a:hover{
    background-color:rgb(239, 210, 68);
    color: white;
    transition: 0.3s;
}
.card{
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 530px;
    margin: 10px;
    transition: 0.3s;
    padding: 10px;
}
.image >img{
    height: 100%;
    width: 100% !important;
    object-fit:contain;
}
.image{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    transition: 0.3s;
 
}
.image:hover{
    cursor: pointer;

    
}
.image >p:hover{
    color:white;
    background-color: black;
}
.image >p{
text-align: left;
font-weight: 500;
font-size: 18px;
transition: 0.3s;
padding: 5px 7px;
font-family: 'Noto Sans Telugu';
 
}
.descti{
    text-align: left;
    margin-top: -5px;
   
}
.descti>p{
 font-size: 15px;
 color:grey;
 text-align: justify;
 font-family: 'Noto Sans Telugu';
}
.popup{
    position: absolute;
    z-index: 5;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.popcard{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}
.time{
    font-weight: 500;
    background-color: dodgerblue;
    color: white !important;
    width: max-content;
    padding: 5px 10px;
}
@media screen and (width<= 982px) {
    .Poliindex{
        width: 70%;
        transition: 0.3s;
    }
}
@media screen and (width< 630px) {
    .Poliindex{
        width: 80%;
        transition: 0.3s;
    }
}
@media screen and (width < 480px) {
    .card{
        width:400px
    }
}
@media screen and (width < 430px) {
    .card{
        width:380px
    }
}
@media screen and (width < 410px) {
    .card{
        width:300px
    }
}